

const Tc = ({ title }) => {
    const domain = "collegekatta.in";
    let fTitle = title ? title + " | " + domain : "Collegekatta.in | Projects | Blogs | Portfolios | programmers adda";
    document.querySelector("title").innerText = fTitle;

    return null;
}


export default Tc