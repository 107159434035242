import axios from "axios";
import Cookies from 'js-cookie';
import { BE_HOST } from "../constants";


const apiAxios = axios.create({
    baseURL: BE_HOST,
    withCredentials: true
})


apiAxios.interceptors.request.use(
    request => {
        let token = Cookies.get('accessToken') || localStorage.getItem("accessToken");
        if (token) {
            if (!request.headers) {
                request.headers = {};
            }

            request.headers["Authorization"] = `Bearer ${token}`;
        }

        return request;
    }
)

apiAxios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return error.response
    }
)

export default apiAxios
