import React from 'react'
import { Card, ListGroup } from 'react-bootstrap'

const RelatedBlogs = ({blogRelated}) => {
    return (
        <>
            <Card.Header className='bg h5'>Related blogs</Card.Header>
            <Card.Body>
                <ListGroup>
                    {
                        blogRelated.length ?
                        blogRelated.map((blog, index) => <ListGroup.Item key={index} action href={`/blogs/d/${blog.slug}`}>{blog.title}</ListGroup.Item>) :
                            <ListGroup.Item className='text-warning'>No Blogs</ListGroup.Item>
                    }
                </ListGroup>
            </Card.Body>
        </>
    )
}

export default RelatedBlogs