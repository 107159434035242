import React from 'react'
import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';

const TermsConditions = () => {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });
    return (
        <>
            <Container fluid={!isLargeScreen} className='mb-5' style={{ marginTop: "5rem" }}>
                <Outlet />
            </Container>

        </>
    )
}

export default TermsConditions