import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

const NavLi = ({ to, children , classes = "nav-link " }) => {
    const { pathname } = useLocation();

    return (

        <NavLink 
            onClick={() => {
                if (document.getElementById("offcanvasNavbar-expand-md") && document.getElementById("offcanvasNavbar-expand-md").classList.contains("show")) {
                    document.querySelector("#offcanvasNavbar-expand-md .btn-close").click();
                }
            }}
            className={`${classes} d-inline-flex justify-content-start align-items-center`}
            activeclassname="active"
            to={to}
            aria-current={pathname.includes(to) ? "page" : undefined}
        >
            {children}
        </NavLink>

    )
}

export default NavLi