import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import apiAxios from "../axios/axios";
// import axios from "axios";

export default function ScrollTop() {
  const { pathname } = useLocation();

//   const visitor = useCallback( async () => {
//     try {
//       const response = await axios.get('https://api.ipify.org/?format=json');
//       const userIp = response.data.ip;
//       await apiAxios.post(`hello`, { pathname, user: userIp })
//     } catch (error) {
      
//     }
//   },[pathname])

  useEffect(() => {
    window.scrollTo(0, 0);
    // visitor();
  }, [pathname]);

  return null;
}
