import React from 'react'
import Form from "react-bootstrap/Form"
import { IoWarningOutline } from "react-icons/io5";


const FormInput = ({
    className,
    type = "text",
    name,
    label,
    value,
    onChangeHandler,
    status = "",
    ...rest
}) => {
    return (
        <>
            <Form.Group className={className + " mb-3"} controlId={name}>
                <Form.Label>{label}</Form.Label>
                <Form.Control
                    className={status[0] === null ? 'border-danger' : ""}
                    type={type}
                    name={name}
                    onChange={onChangeHandler}
                    value={value}
                    {...rest}
                />
                {status[0] === null ?
                    <Form.Text className='warning'><IoWarningOutline style={{ width: "16px", height: "16px" }} /> &nbsp;{status[1]}</Form.Text>
                    : ""
                }
            </Form.Group>
        </>
    )
}

export default FormInput