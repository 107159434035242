import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Toaster from "../UI/Toaster";
import apiAxios from "../../axios/axios";

const NewsLetter = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState("");
  const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", title: "Alert", message: "" })

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)

    try {
      const res = await apiAxios.post("/support/newsletter", { email });
      if (res.status === 200) {
        setToasterState(prevState => { return { ...prevState, isOpen: true, varient: "success", message: res.data.message } })  
        setEmail("")
      }else{
        setToasterState(prevState => { return { ...prevState, isOpen: true, varient: "danger", message: res.data.message } })  
      }

    } catch (error) {

    }

    setIsLoading(false)
  }


  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}

      <h4 className='text-orange'>Subscribe to our newsletter</h4>
      <p>Monthly digest of whats new and exciting from us.</p>

      <div className="d-flex subscribe-input w-100 gap-2">
        <Form.Group controlId="email">
          <Form.Control required name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder={`Enter email address`} />
        </Form.Group>

        <button disabled={isLoading && 'disabled'} className="btn btn-primary" type="submit">
          { isLoading && <><Spinner animation="border" size="sm" /></>}
          {!isLoading && "Subscribe"}
        </button>
      </div>
    </Form>
  );
};

export default NewsLetter;