import React from 'react'
import { Badge, Card, ListGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Categories = ({ onlyTags = false }) => {
    const { categories, tags } = useSelector(state => state.blogs)

    const allUniqueTags = new Set();
    if (tags && tags.length > 0) {

        tags.forEach(tagObject => {
            tagObject.uniqueTags.forEach(tag => {
                allUniqueTags.add(tag.toLowerCase());
            });
        });
    }

    const uniqueTagsArray = Array.from(allUniqueTags);

    if (onlyTags) {
        return (
            <>
                <Card.Header className='bg mt-3 h5'>Browse by Tags</Card.Header>
                <Card.Body>
                    {uniqueTagsArray.length ? uniqueTagsArray.map(tag => (
                        <Link to={`/blogs/search?tags=${tag}`} key={tag}>
                            <Badge className='mx-1 my-2 p-2' bg="secondary">{tag}</Badge>
                        </Link>
                    )) : <ListGroup.Item className='text-warning'>No Tags</ListGroup.Item>}

                </Card.Body>

            </>
        )
    }

    return (
        <Card className='mb-5 mb-md-0'>
            <Card.Header className='bg h5'>Browse by categories</Card.Header>
            <Card.Body>
                <ListGroup>
                    {
                        categories.length ?
                            categories.map((cat, index) => <ListGroup.Item key={index} action href={`/blogs/search?cats=${cat.slug}`}>{cat.name}</ListGroup.Item>) :
                            <ListGroup.Item className='text-warning'>No categories</ListGroup.Item>
                    }
                </ListGroup>
            </Card.Body>

            <Card.Header className='bg mt-3 h5'>Browse by Tags</Card.Header>
            <Card.Body>
                {uniqueTagsArray.length ? uniqueTagsArray.map(tag => (
                    <Link to={`/blogs/search?tags=${tag}`} key={tag}>
                        <Badge className='mx-1 my-2 p-2' bg="secondary">{tag}</Badge>
                    </Link>
                )) : <ListGroup.Item className='text-warning'>No Tags</ListGroup.Item>}

            </Card.Body>

        </Card>
    )
}

export default Categories