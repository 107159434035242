import React from "react";

import { BsInstagram, BsLinkedin, BsYoutube } from 'react-icons/bs'
import { instagram, linkedin, youtube } from "../../constants";

import { Link } from 'react-router-dom'

const SocialMediaLinks = ({ classes }) => {
    const classlist = classes !== '' ? classes : ""
    return (
        <ul className={`${classlist} list-unstyled footer_icons d-flex justify-content-center align-items-center`}>
            <li className="ms-3">
                <Link title="Instagram" target="_BLANK" href={instagram}>
                    <BsInstagram />
                </Link>
            </li>
            <li className="ms-3">
                <Link title="youtube" target="_BLANK" href={youtube}>
                    <BsYoutube />
                </Link>
            </li>
            <li className="ms-3">
                <Link title="LinkedIn" target="_BLANK" href={linkedin}>
                    <BsLinkedin />
                </Link>
            </li>
        </ul>
    );
};

export default SocialMediaLinks;