import React from 'react'
import Tc from '../../utils/TC'
import { Alert, Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import Categories from './Categories'
import { IoSearchCircleOutline } from 'react-icons/io5'
import { icon_size_24 } from '../../constants'
import { useSelector } from 'react-redux'
import BlogListCard from '../UI/BlogListCard'

const Blogs = () => {
    const { blogs } = useSelector(state => state.blogs)
    const blogsList = blogs?.blogs;


    // const getBlogs

    return (
        <>
            <Tc title='Blogs' />

            <Row className='flex-row'>
                <Col sm={8} className='m-auto order-0'>
                    <div className='my-5'>
                        <InputGroup>
                            <Form.Control
                                placeholder="Search by title, category"
                            />
                            <Button variant="outline-dark text-white" >
                                <IoSearchCircleOutline style={icon_size_24} />
                            </Button>
                        </InputGroup>
                    </div>
                </Col>
                <hr />
                <Col md="4" className='order-2 order-md-1'>
                    <Categories />
                </Col>
                <Col md="8" className='order-1 order-md-2'>
                    {
                        (blogsList && blogsList.length) ? blogsList.map((blog, index) =>
                            <BlogListCard
                                key={index}
                                title={blog.title}
                                img={blog.featuredImage}
                                link={`/blogs/d/${blog.slug}`}
                                likes={blog.likes}
                                views={blog.views}
                            >
                                {blog?.inshort}
                            </BlogListCard>) : <Alert variant='warning'>No blogs</Alert>
                    }
                </Col>
            </Row>
        </>
    )
}

export default Blogs