import apiAxios from "../axios/axios";

export const handleScroll = (direction, scrollPosition, setScrollPosition, navContainerRef) => {
  if (navContainerRef.current) {
    const container = navContainerRef.current;
    const maxScrollPosition = container.scrollWidth - container.clientWidth;
    let newScrollPosition = scrollPosition;

    if (direction === ">") {
      newScrollPosition = Math.min(scrollPosition + 50, maxScrollPosition);
    } else if (direction === "<") {
      newScrollPosition = Math.max(scrollPosition - 50, 0);
    }

    container.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
    setScrollPosition(newScrollPosition);
  }
};


export const validateData = (data) => {
  const errors = {};

  Object.keys(data).forEach(field => {
    if (!data[field] || data[field] === "") {
      errors[field] = [data[field] === "" ? null : "", "This field is required!"];
    }
  });

  return Object.keys(errors).length > 0 ? errors : false;
}


export const searchFilter = (list, keyword) => {
  if (!keyword) {
    return list;
  }

  const lowerCaseKeyword = keyword.toLowerCase();

  return list.filter(blog => {
    return Object.keys(blog).some(key => {
      if (typeof blog[key] === 'string') {
        return blog[key].toLowerCase().includes(lowerCaseKeyword);
      }
      if (Array.isArray(blog[key])) {
        return blog[key].some(item => item.toLowerCase().includes(lowerCaseKeyword));
      }
      return false;
    });
  });

};


export const getCategories = async () => {
  const res = await apiAxios.get("/blogs/categories")

  if (res?.status === 200) {
    return res.data?.data.categories
  }

  return false

}

export const dateConverter = (isoDate) => {

  // Create a Date object from the ISO date string
  const date = new Date(isoDate);

  // Convert to IST timezone (UTC+5:30)
  date.setMinutes(date.getMinutes() + 330);

  // Get day, month, and year components in IST timezone
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Add 1 to the month because it is zero-indexed
  const year = date.getUTCFullYear();

  // Format the date as "dd/mm/yyyy"
  const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

  return formattedDate;

}