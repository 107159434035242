import React from 'react';
import { Link, useRouteError, useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="row">
        <div className="col-sm-12">
          <div className="col-sm-10 col-sm-offset-1 text-center">
            <div className="four_zero_four_bg">
              <h1 className="text-center">{error.status}</h1>
            </div>

            <div className="contant_box_404">
              <p className="h2">Look like you're lost</p>
              <p>The page you are looking for is not available!</p>
              <button className='btn btn-success px-4' onClick={() => navigate(-1)}>Go Back</button>
              <br />
              <Link className='btn border mt-3 text-white' to="/">Go to Home</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
