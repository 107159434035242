import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BLOGS_HOST, MAIN_HOST } from "../../constants";
import Tc from "../../utils/TC";


function Home() {
  return (
    <>
<Tc title='' />
        <Card>
          <Card.Body className="p-1 pb-5 pt-3">
            <h4 className="text-center text-orange text-decoration-underline my-2">🌟 Welcome to Our Creative Hub! 🌟</h4>
            <hr />
            <p className="ps-3 pt-3">Dive into a world where creativity meets technology. Our platform offers an array of exciting features designed to unleash your potential: 👇 </p>
            <ul className="my-4">
              <li className="my-2">
                <Link to={BLOGS_HOST} >📝 Blogs:</Link> Explore our wide range of articles or share your own stories and insights. <span className="highlight">Everyone has a voice here!</span>
              </li>
              <li className="my-2">
                <Link to={MAIN_HOST}>🎨 Portfolio Themes: </Link> Choose from stunning portfolio themes, fill in your details, and get a shareable link to showcase your work/skills effortlessly.
              </li>

              <li className="my-2">
              <Link to={MAIN_HOST + "/ck-projects"}>💻 Student Projects:</Link> College students, we've got you covered! Access a variety of projects in multiple programming languages to ace your academic requirements.
              </li>

              <li className="my-2">
                <Link to={MAIN_HOST + "/development"}>🌐 Web & App Development:</Link> Need a website or an app? Our expert team delivers top-notch development services tailored to your needs.
              </li>

              <li className="my-2">
                <Link to={MAIN_HOST + "/contribution"}>🤝 Collaborate & Contribute:</Link> Join our community of like-minded individuals, <span className="highlight">contribute to ongoing projects, share your skills,</span> and help make our community stronger and better.
              </li>
            </ul>

            <p className="text-center">🚀 Join us and be part of something extraordinary! 🚀</p>

            <div className="text-center">
              <Link to={BLOGS_HOST} className="m-2 btn btn-primary">Explore Blogs 📝</Link>
              <Link to={MAIN_HOST} className="m-2 btn btn-primary">Portfolio 🎨</Link>
              <Link to={MAIN_HOST + "/ck-projects"} className="m-2 btn btn-primary">Get Projects 💻</Link>
              <Link to={MAIN_HOST + "/contribution"} className="m-2 btn btn-primary">Ongoing Projects 🚀</Link>
            </div>
          </Card.Body>
        </Card>
    </>
  );
}

export default Home;
