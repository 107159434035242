import { configureStore } from "@reduxjs/toolkit"
import authSlice from "./auth/authSlice"
import blogSlice from "./blogs/blogSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        blogs: blogSlice
    }
})

export default store;
