export const BE_HOST = `https://app.collegekatta.in/api/v1`

export const MAIN_HOST= `https://collegekatta.in`;

export const ACCOUNT_HOST= `https://account.collegekatta.in`;

export const BLOGS_HOST= `${MAIN_HOST}/blogs`;

export const icon_size_20 = { width: "20px", height: "20px" }
export const icon_size_18 = { width: "18px", height: "18px" }
export const icon_size_24 = { width: "24px", height: "24px" }
export const icon_size_16 = { width: "16px", height: "16px" }

export const instagram = "https://www.instagram.com/collegekatta.in/"
export const youtube = "https://www.youtube.com/@collegekatta-in"
export const linkedin = "https://www.linkedin.com/in/collegekatta-in/"
