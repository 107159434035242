import React from 'react';
import { RiLoaderFill } from 'react-icons/ri';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingIconCircle = styled.span`
  display: inline-block;
  animation: ${rotate} 1s infinite linear;
`;

const Loader = ({ type = 'full', loader = 'icon' }) => {
  const Container = styled.div`
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    position: ${type === "full" ? "fixed" : "static"};
    top: ${type === "full" ? "0" : "0"};
    left: ${type === "full" ? "0" : "0"};
    width: 100%;
    height: 100%;
  `;

  return (
    <Container>
      {loader === 'icon' && (
        <LoadingIconCircle>
          <RiLoaderFillStyled />
        </LoadingIconCircle>
      )}
    </Container>
  );
};

const RiLoaderFillStyled = styled(RiLoaderFill)`
  width: 35px;
  height: 35px;
  color: white;
`;

export default Loader;
