import React from 'react'
import TopHeader from './header_footer/TopHeader'
import { Container } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';
import BottomFooter from './header_footer/BottomFooter';
import ScrollTop from '../utils/ScrollTop';

const Layout = () => {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

    return (
        <>
            {/* Top header */}
            <ScrollTop />
            <TopHeader />
            <Container fluid={!isLargeScreen} className='mb-5' style={{ marginTop: "4rem" }}>
                <Outlet />
            </Container>

            <BottomFooter />

        </>
    )
}

export default Layout