import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    categories: {},
    tags: {},
    blogs: {}
}


const blogSlice = createSlice({
    name: "blogs",
    initialState,
    reducers: {
        setTags: (state, action) => {
            state.tags = action.payload.data;
        },
        setCategories: (state, action) => {
            state.categories = action.payload.data;
        },
        setBlogs: (state, action) => {
            state.blogs = action.payload.data;
        }
     }
})

export const { setTags, setCategories, setBlogs } = blogSlice.actions;

export default blogSlice.reducer;