import React  from 'react'
import { Card, Container } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import { BE_HOST } from '../../constants';
import SupportForm from './SupportForm';
import { MdOutlineEmail, MdOutlineWhatsapp } from 'react-icons/md';

const Support = () => {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });
    return (
        <>
            <Container fluid={!isLargeScreen} className='mb-5' style={{ marginTop: "4rem" }}>

                <div className="container support-container  mb-4">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <Card className='rounded'>
                                <Card.Body className='rounded'>
                                    <h4 className='text-center'>Lets talk about everything!</h4><hr />
                                    <div className="img support-logo w-50 text-center m-auto"><img src={`${BE_HOST}/assets/ck/collegekatta-logo-colored.png`} className="shadow m-auto" width="100%" alt="collegekatta" /></div>
                                    <p className="py-4 text-start" style={{ lineHeight: "1.25" }}>Have doubt or suggestion to make? Feel free to ask anything. If you have any suggestions, please let me know. Your suggestions are highly appreciated. I appreciate your time and try hard to reply to every single message posted here! Keep dropping your priceless opinions.</p>
                                    <hr />
                                    <h5 className="text-info">Contact</h5>
                                    <p className='mb-0 pb-0'>
                                        <MdOutlineWhatsapp />  Whatsapp: (+91) 9595 376 524
                                    </p>
                                    <p className='mt-0 pt-0'>
                                        <MdOutlineEmail />  Email: enquiry.collegekatta[at]gmail.com
                                    </p>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-12 col-sm-6">
                            <SupportForm />
                        </div>
                    </div>
                </div>

            </Container>

        </>
    )
}

export default Support