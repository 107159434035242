import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

/**
 * Toaster Component
 *
 * A reusable component that renders a toast notification using React Bootstrap's `Toast` and `ToastContainer`.
 * The `Toaster` component displays a customizable toast notification with a heading, content, and a close button.
 *
 * @param {object} props - The component's props.
 * @param {function} props.hideToast - Function to set the toast show state in the parent component.
 * @param {object} props.showToast - An object representing the toast show state with the following properties:
 *                                   - isOpen: boolean (whether the toast is visible or not).
 *                                   - title: string (the heading/title of the toast).
 *                                   - message: string (the content/message to be displayed in the toast).
 * @param {number} [props.delay=3000] - The delay (in milliseconds) before the toast automatically hides (default: 3000ms).
 *
 * @returns {JSX.Element} - The rendered React element representing the Toaster component.
 */
const Toaster = ({ showToast, hideToast, delay = 5000 }) => {

    return (
        <ToastContainer className="p-3" containerPosition="fixed" position="top-end">
            <Toast
                onClose={() => hideToast({ isOpen: false })}
                show={showToast.isOpen}
                bg={showToast.varient}
                delay={delay}
                autohide
            >
                <Toast.Header>
                    {/* <img
                        src={require("../../assets/images/ck/collegekatta-favicon.png")}
                        className="rounded me-2"
                        style={{ width: "20px", height: "20px" }}
                        alt="collegekatta"
                    /> */}
                    <strong className="me-auto">{"Alert"}</strong>
                    <small>Just now</small>
                </Toast.Header>
                <Toast.Body>{showToast.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default Toaster;
