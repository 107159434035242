import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import apiAxios from '../../axios/axios';
import Loader from '../UI/Loader';
import { setBlogs, setCategories, setTags } from '../../store/blogs/blogSlice';

const BlogsLayout = () => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true)

    const { categories, tags, blogs } = useSelector(state => state.blogs)


    const fetchCategories = useCallback(async () => {

        if ((categories && categories.length > 0) && (tags && tags.length > 0) && (blogs && blogs.length > 0)) {
            setLoader(false);
            return;
        }

        try {
            const [categoriesRes, tagRes, topBlogsRes] = await Promise.all([
                apiAxios.get("/blogs/categories"),
                apiAxios.get("/blogs/tags"),
                apiAxios.get("/blogs?get=[top, new]"),
            ]);

            if (tagRes?.status === 200) {
                dispatch(setTags({ data: tagRes.data?.data }));
            }

            if (categoriesRes?.status === 200) {
                dispatch(setCategories({ data: categoriesRes.data?.data.categories }));
            }

            if (topBlogsRes?.status === 200) {
                dispatch(setBlogs({ data: topBlogsRes.data?.data }));
            }
        } catch (error) { }

        setLoader(false);
    }, [dispatch]);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    return (
        <>
            {loader && <Loader />}
            {!loader && <Outlet /> }

        </>
    )
}

export default BlogsLayout