import React from 'react'
import { Button, ButtonGroup, Card } from 'react-bootstrap'
import { FiEye } from 'react-icons/fi'
import { GoHeartFill } from 'react-icons/go'
import { Link } from 'react-router-dom'
import { icon_size_18 } from '../../constants'

const BlogListCard = ({ title, img, link, likes = 0, views = 0, children }) => {
    return (
        <Link to={link} className='text-white text-decoration-none'>
            <Card className='flex-column flex-sm-row mb-4 blog-card'>
                <div className='overflow-hidden blog-card-img'>
                    <Card.Img height={"100%"} width={"100%"} src={img} />
                </div>
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text dangerouslySetInnerHTML={{ __html: children }} />
                    <div className='d-flex justify-content-between align-items-center' >
                        <ButtonGroup size='sm'>
                            <Button size='sm' variant="outline-secondary">{likes}&nbsp;<GoHeartFill style={icon_size_18} /></Button>
                            <Button size='sm' variant="outline-secondary">{views}&nbsp;<FiEye style={icon_size_18} /></Button>
                        </ButtonGroup>
                        <Button variant='outline-secondary'>Read...</Button>
                    </div>
                </Card.Body>
            </Card>
        </Link>
    )
}

export default BlogListCard