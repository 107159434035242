import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Button, ButtonGroup, Card, Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import apiAxios from '../../axios/axios';
import { dateConverter } from '../../utils/helpers';
import { GoHeartFill } from 'react-icons/go';
import { icon_size_18 } from '../../constants';
import { FiEye } from 'react-icons/fi';
import RelatedBlogs from './RelatedBlogs';
import Categories from './Categories';

const BlogDetails = () => {
    const { blogSlug } = useParams();
    const [blogDetails, setBlogDetails] = useState(false)
    const [blogRelated, setBlogRelated] = useState(false)

    const getBlogDetails = useCallback(async () => {

        try {
            const res = await apiAxios.get(`/blogs/d/${blogSlug}`);

            if (res.status === 200) {
                setBlogDetails(res.data?.data.blog[0]);
                setBlogRelated(res.data?.data?.related)
            }
        } catch (error) {

        }

    }, [blogSlug])

    useEffect(() => {
        getBlogDetails()
    }, [getBlogDetails])
    return (
        <>

            <Row >
                <Col className='order-1 order-md-0' md={3}>
                    <Card className='mt-5 mt-md-0'>
                        <RelatedBlogs blogRelated={blogRelated} />
                        <Categories onlyTags />
                    </Card>
                </Col>
                {blogDetails ?
                    <Col className='order-0 order-md-1' md={9}>
                        <Card>
                            <div style={{ width: "100%", height: "250px", overflow: "hidden" }}>
                                <Card.Img style={{ width: "100%", height: "100%" }} src={blogDetails.featuredImage} />
                            </div>
                            <Card.Header className='mt-3'>
                                <div className='h4 text-center text-info'>{blogDetails.title}</div>
                                <div className='d-flex'>
                                    <div className='border rounded-circle overflow-hidden' style={{ width: "40px", height: "40px" }}><img alt={blogDetails?.author.fullName.split(" ")[0]} className='w-100 h-100' src={blogDetails?.author.avatar && blogDetails?.author.avatar} /></div>
                                    <div className='d-inline-flex flex-column flex-grow-1'>
                                        <small className='mx-2'>{blogDetails?.author.fullName.split(" ")[0]}</small>
                                        <small className='mx-2'>Published : {dateConverter(blogDetails.publishedAt)}</small>
                                    </div>
                                    <div >
                                        <ButtonGroup size='sm'>
                                            <Button size='sm' variant="outline-secondary">{blogDetails.likes}&nbsp;<GoHeartFill style={icon_size_18} /></Button>
                                            <Button size='sm' variant="outline-secondary">{blogDetails.views}&nbsp;<FiEye style={icon_size_18} /></Button>
                                        </ButtonGroup>
                                    </div>

                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text dangerouslySetInnerHTML={{ __html: blogDetails.content }} />
                            </Card.Body>

                            {/* <Card.Header className='bg h5'>
                                Comments
                            </Card.Header>

                            <Card.Body>
                                <Card.Text dangerouslySetInnerHTML={{ __html: blogDetails.content }} />
                            </Card.Body> */}
                        </Card>
                    </Col>
                    :
                    <Alert variant='warning' className='my-3'>
                        Seems like this blog is not  ready, Please explore related blogs
                    </Alert>
                }
            </Row>

        </>
    )
}

export default BlogDetails