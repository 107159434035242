import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Loader from './components/UI/Loader'
import { useDispatch } from 'react-redux'
import { login, logout } from './store/auth/authSlice'
import apiAxios from './axios/axios'
import { initGA, logPageView } from './components/ga'


const measurementID = 'G-QD46D165D1';

const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
        logPageView();
    }, [location]);

    return null;
};


const App = () => {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const getLoggedUser = useCallback(async () => {
        try {
            const res = await apiAxios.get("/account");

            if (res.status === 200) {
                dispatch(login({ userData: res.data?.data.user }))
            } else {
                const res = await apiAxios.post("/account/auth/refresh-token", { refreshToken: localStorage.getItem("refreshToken") });
                if (res.status === 200) {
                    dispatch(login({ userData: res.data?.data.user }))
                    localStorage.setItem("refreshToken", res.data?.data.refreshToken)
                }
            }

        } catch (error) {
            dispatch(logout());
        }

        setLoading(false)
    }, [dispatch])

    useEffect(() => {
        getLoggedUser();
    }, [getLoggedUser])

    useEffect(() => {
        initGA(measurementID);
    }, []);

    return loading ? <Loader /> : <><RouteChangeTracker /><Outlet /></>
}

export default App