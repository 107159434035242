import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Form, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import FormInput from '../UI/FormInput'
import { TbAddressBook, TbPhoneCheck } from 'react-icons/tb'
import { MdOutlineMailOutline, MdOutlineMessage } from 'react-icons/md'
import Toaster from '../UI/Toaster'
import apiAxios from '../../axios/axios'
import { validateData } from '../../utils/helpers'

const SupportForm = () => {
    const { userData, isLoggedIn } = useSelector(state => state.auth)

    const [supportDetails, setSupportDetails] = useState({ name: "", email: "", mobile: "", message: "" })
    const [supportStatus, setSupportStatus] = useState({ name: "", email: "", mobile: "", message: "" })
    const [loading, setLoading] = useState(false)
    const [toasterState, setToasterState] = useState({ isOpen: false, varient: "danger", message: "" })


    const setInitial = useCallback(async () => {
        if(userData){
            setSupportDetails({ _id: userData?._id, isLoggedIn: isLoggedIn, name: userData?.fullName, email: userData?.email, mobile: userData?.mobile, message: "" })
        }
    }, [isLoggedIn, userData])

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setSupportDetails(prevState => { return { ...prevState, [name]: value } })
        setSupportStatus(prevState => { return { ...prevState, [name]: value !== "" ? "" : [null, "This field is required"] } })
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const validations = validateData(supportDetails);

        if (validations) {
            setSupportStatus(validations)
            setLoading(false);
            return;
        }

        try {
            const res = await apiAxios.post("/support", { ...supportDetails });
            if (res.status === 200) {
                setSupportDetails(prevState => { return {...prevState, message: ""}})
            } else {
                const er = res.data.errors;
                const errors = Object.fromEntries(
                    Object.entries(er).map(([key, value]) => [key, [null, value]])
                );
                setSupportStatus(errors)
            }

            setLoading(false);
            setToasterState({ isOpen: true, varient: res.data.success ? "success" : "danger", message: res.data.message })
        } catch (error) {

        }
    }


    useEffect(() => {
        setInitial();
    }, [setInitial])

    return (
        <>
            {toasterState.isOpen && <Toaster showToast={toasterState} hideToast={setToasterState} />}

            <Card className='mt-3 mt-sm-0 rounded'>

                <Card.Body className='rounded'>
                    <h4 className='text-center'>Support form!</h4>
                    <hr />
                    <Form onSubmit={onSubmitHandler}>
                        <Row>

                            <FormInput
                                className="col-12"
                                name={"fullName"}
                                value={supportDetails.name}
                                label={<><TbAddressBook />&nbsp; Full name</>}
                                placeholder="First Middle Last name"
                                onChange={onChangeHandler}
                                status={supportStatus?.name}
                            />

                            <FormInput
                                className="col-md-6"
                                type="email"
                                name={"email"}
                                value={supportDetails.email}
                                label={<><MdOutlineMailOutline />&nbsp; Email Id.</>}
                                placeholder="user@domain.com"
                                onChange={onChangeHandler}
                                status={supportStatus?.email}
                            />

                            <FormInput
                                className="col-md-6"
                                name={"mobile"}
                                value={supportDetails.mobile}
                                label={<><TbPhoneCheck />&nbsp; Mobile No.</>}
                                placeholder="1234567890"
                                onChange={onChangeHandler}
                                status={supportStatus?.mobile}
                            />

                        </Row>

                        <FormInput
                            name={"message"}
                            value={supportDetails.message}
                            label={<><MdOutlineMessage />&nbsp; Enter message</>}
                            placeholder='Enter message, Describe you issue, Your suggestions'
                            as="textarea" rows={3}
                            onChange={onChangeHandler}
                            status={supportStatus?.message}
                        />

                        <Button disabled={loading && true} type="submit" className="btn btn-primary px-3">
                            {loading && <><Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Loading...</>}
                            {!loading && "Send message"}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}

export default SupportForm