import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import ErrorPage from "../components/UI/ErrorPage";
import Layout from "../components/Layout";
import Home from "../components/home/Home";
import Blogs from "../components/blogs/Blogs";
import BlogDetails from "../components/blogs/BlogDetails";
import BlogsLayout from "../components/blogs/BlogsLayout";
import Support from "../components/support/Support";
import TermsConditions from "../components/TermsConditions/TermsConditions";
import UnderMain from "../components/UI/UnderMain";
// import SearchBlogs from "../components/blogs/SearchBlogs";


const router = createBrowserRouter([
    {
        path: "",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <Layout />,
                children: [

                    {
                        path: "",
                        element: <Home />
                    },
                    {
                        path: "blogs",
                        element: <BlogsLayout />,
                        children: [
                            {
                                path: "",
                                element: <Blogs />
                            },
                            {
                                path: "d/:blogSlug",
                                element: <BlogDetails />
                            },
                            // {
                            //     path: "search",
                            //     element: <SearchBlogs />
                            // }
                        ]
                    },
                    {
                        path: "contribution",
                        element: <UnderMain />
                    },
                    {
                        path: "development",
                        element: <UnderMain />
                    },
                    {
                        path: "ck-projects",
                        element: <UnderMain />
                    },
                    {
                        path: "support",
                        element: <Support />
                    },
                    {
                        path: "tc",
                        element: <TermsConditions />,
                        children: [
                            {
                                path: "terms-of-service",
                                element: "terms-of-service"
                            }
                        ]
                    }

                ]
            }
        ]
    }

])

export default router