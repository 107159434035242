import React from 'react'
import { Link } from 'react-router-dom'

import NewsLetter from './NewsLetter'
import SocialMediaLinks from './SocialMediaLinks';

const BottomFooter = () => {

    return (
        <div id="footer" className="container-fluid shadow rounded mt-3">
            <footer className="container pt-5">
                <div className="row">
                    <div className="col-12 subscribe_cont col-sm-5">
                        <NewsLetter />
                        <hr />
                        <SocialMediaLinks />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-5 col-sm-3">
                        <h5 className='text-orange'>Resources</h5>
                        <ul className="nav flex-column">
                            <li className="mb-2"><Link className="nav-link p-0" to="/blogs">Blogs</Link></li>
                            <li className="mb-2"><Link className="nav-link p-0" to="/ck-projects">College projects</Link></li>
                            <li className="mb-2"><Link className="nav-link p-0" to="/contribution">Contribute</Link></li>
                        </ul>
                    </div>

                    <div className="col-5 col-sm-3">
                        <h5 className='text-orange'>Other</h5>
                        <ul className="nav flex-column">
                            <li className="mb-2"><Link className="nav-link p-0" to="/support">Contact us</Link></li>
                            <li className="mb-2"><Link className="nav-link p-0" to="/tc/terms-of-service">Terms of Service</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="d-flex text-secondary justify-content-between py-4 border-top">
                    <p>Copyright © 2024 collegekatta.in</p>
                </div>
            </footer>
        </div>
    )
}

export default BottomFooter;